import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { Helmet } from 'react-helmet-async';

export const AboutPage = (props) => {
  const data = props.data;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div id="about-page">
      <Helmet>
        <title>Voice AI | Our Mission to Revolutionize AI-Powered Voice Solutions</title>
        <meta name="description" content="Learn about Vozzo.ai, our mission to revolutionize communication with AI-powered voice bots and provide AI voicebots for various use cases" />
      </Helmet>
      <Navigation />
      {/* Company Story */}
      <div className="text-center">
        <div className="company-story section-title">
          <h2>{data ? data.companyStory.title : "Loading..."}</h2>
          <p className="company-story-p">{data ? data.companyStory.content : "Loading..."}</p>
        </div>

        {/* Wave Pattern */}
        <div className="wave-container">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 40"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              ></path>
            </defs>
            <g className="moving-waves">
              <use xlinkHref="#gentle-wave" x="48" y="-1" fill="rgb(38, 154, 216)" />
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgb(50, 160, 218)" />
              <use xlinkHref="#gentle-wave" x="48" y="1" fill="rgb(63, 167, 221)" />
              <use xlinkHref="#gentle-wave" x="48" y="2" fill="rgb(75, 173, 223)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgb(88, 179, 225)" />
              <use xlinkHref="#gentle-wave" x="48" y="4" fill="rgb(100, 185, 228)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgb(113, 191, 230)" />
              <use xlinkHref="#gentle-wave" x="48" y="6" fill="rgb(125, 197, 232)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgb(138, 203, 235)" />
              <use xlinkHref="#gentle-wave" x="48" y="8" fill="rgb(150, 209, 237)" />
              <use xlinkHref="#gentle-wave" x="48" y="9" fill="rgb(163, 215, 239)" />
              <use xlinkHref="#gentle-wave" x="48" y="10" fill="rgb(175, 221, 242)" />
              <use xlinkHref="#gentle-wave" x="48" y="11" fill="rgb(188, 227, 244)" />
              <use xlinkHref="#gentle-wave" x="48" y="12" fill="rgb(200, 233, 246)" />
              <use xlinkHref="#gentle-wave" x="48" y="13" fill="rgb(213, 239, 249)" />
              <use xlinkHref="#gentle-wave" x="48" y="14" fill="rgb(225, 245, 251)" />
              <use xlinkHref="#gentle-wave" x="48" y="15" fill="rgb(238, 251, 253)" />
              <use xlinkHref="#gentle-wave" x="48" y="16" fill="rgb(250, 255, 255)" />
              <use xlinkHref="#gentle-wave" x="48" y="17" fill="rgb(255, 255, 255)" />
            </g>
          </svg>
        </div>
      </div>

      {/* Mission, Vision, Values */}
      <div id="mission-section">
        <div className="container mission-vision-values">
          <div className="row">
            <div className="col-md-4 mission-item">
              <h2>Mission</h2>
              <p>{data ? data.missionVisionValues.mission : "Loading..."}</p>
            </div>
            <div className="col-md-4 mission-item">
              <h2>Vision</h2>
              <p>{data ? data.missionVisionValues.vision : "Loading..."}</p>
            </div>
            <div className="col-md-4 mission-item">
              <h2>Values</h2>
              <p>{data ? data.missionVisionValues.values : "Loading..."}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Team */}
      {/* <div id="team-section">
        <div className="container team section-title">
          <h2>{data ? data.team.title : "Loading..."}</h2>
          <div className="row">
            {data && data.team.members
              ? data.team.members.map((member, index) => (
                <div className="col-md-4 team-member" key={index}>
                  <img src={member.image} alt={member.name} />
                  <div>
                    <h3>{member.name}</h3>
                    <p>
                      {member.role} - {member.bio}
                    </p>
                  </div>
                </div>
              ))
              : "Loading..."}
          </div>
        </div>
      </div> */}
    </div>
  );
};

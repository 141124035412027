import React, { useEffect } from "react";
import { Navigation } from "./navigation";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export const BlogsPage = ({ data }) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div id="blogs-page">
            {/* Global Meta Tags for Blog Listing Page */}
            <Helmet>
                <title>Insights on AI-Powered Voice Botss | Vozzo.ai</title>
                <meta
                    name="description"
                    content="Explore insights on AI-powered voice bots with Vozzo.ai. Learn about their benefits, applications, and how they are transforming industries with smarter communication solutions."
                />

                {/* Open Graph Meta Tags (For Sharing on Facebook, WhatsApp, LinkedIn) */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Our Latest Blogs | Vozzo.ai" />
                <meta property="og:description" content="Stay updated with the latest trends in AI and voice bot technology." />
                <meta property="og:image" content="https://vozzo.ai/default-blog-thumbnail.jpg" />
                <meta property="og:url" content="https://vozzo.ai/blogs" />

                {/* Twitter Card Meta Tags (For Twitter Sharing) */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Our Latest Blogs | Vozzo.ai" />
                <meta name="twitter:description" content="Stay updated with the latest trends in AI and voice bot technology." />
                <meta name="twitter:image" content="https://vozzo.ai/default-blog-thumbnail.jpg" />
            </Helmet>

            <Navigation />
            <div className="text-center">
                <div className="section-title">
                    <h2>Our Latest Blogs</h2>
                    <p>Stay up-to-date with the latest news and trends in AI and machine learning.</p>
                </div>
            </div>

            <div id="blogs-section">
                <div className="blogs">
                    <div className="row">
                        {data && data.length > 0 ? (
                            data.map((blog, index) => (
                                <div key={index} className="col-lg-4 col-md-6 mb-4 card">
                                    {/* Dynamic Meta Tags for Each Blog (Only on Individual Blog Page) */}
                                    {window.location.pathname === blog.link && (
                                        <Helmet>
                                            <meta property="og:title" content={blog.title} />
                                            <meta property="og:description" content={blog.excerpt} />
                                            <meta property="og:image" content={blog.image} />
                                            <meta property="og:url" content={`https://vozzo.ai${blog.link}`} />

                                            <meta name="twitter:card" content="summary_large_image" />
                                            <meta name="twitter:title" content={blog.title} />
                                            <meta name="twitter:description" content={blog.excerpt} />
                                            <meta name="twitter:image" content={blog.image} />
                                        </Helmet>
                                    )}

                                    <div className="card-header">
                                        <Link to={blog.link}>
                                            <img src={blog.image} className="img-fluid" alt="Blog thumbnail" />
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <div className="card-title">
                                            <Link to={blog.link}>{blog.title}</Link>
                                        </div>
                                        <div className="card-description">
                                            <p>{blog.excerpt}</p>
                                        </div>
                                        <div className="author">
                                            <img src={blog.authorImage} alt="Author" className="avatar shadow" />
                                            <div className="author-name">
                                                <span>{blog.author}</span>
                                                <div className="author-stats">
                                                    <small>Posted on {blog.date}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center">No blogs available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
import React, { useState } from 'react';
import { VozzoAgentWeb } from "../pages/LiveDemo/VozzoAgentLiveWeb";

export const Footer = (props) => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const startConnection = () => {
        setIsLoading(true);
        // Simulate an async operation
        setTimeout(() => {
            setIsLoading(false);
            setIsConnected(true);
        }, 2000);
    };

    const endConnection = () => {
        setIsConnected(false);
    };

    return (
        <div className="col-md-12 footer-container">
            <div className="row">
                <div className="social">
                    <ul>
                        <li><a href={props.data ? props.data.linkedin : "/"} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
                        <li><a href={props.data ? props.data.twitter : "/"}><i className="fab fa-x-twitter"></i></a></li>
                        <li><a href={props.data ? props.data.youtube : "/"}><i className="fab fa-youtube"></i></a></li>
                    </ul>
                    <div id="footer" className="text-center">
                        <p>&copy; 2024 Vozzo AI Labs</p>
                    </div>
                </div>
            </div>
            {<VozzoAgentWeb agent_uuid="ubzIxSP3xNBTzeYxZy" /> }
        </div>
    );
};


